<template>
    <div class="create-viewing-key-btn" v-if="!userAuth || requireRegenerate && !dataLoading">
        <button v-if="!isConnected" @click="bootstrap">CONNECT TO KEPLR</button>
        <button v-else @click="authenticateUser">{{ text }}</button>
        <small v-if="error">Could not create {{ authMethod }}, try again</small>
    </div>
</template>

<script>
import {
    onAccountAvailable,
    refContract,
    bootstrap,
isAccountAvailable
} from '@stakeordie/griptape.js';
import { isUserAuthenticated, authenticateUser,getAuthMethod } from '@/utils.js'
const texts = Object.freeze({
    default: 'View collection',
    loading: 'Loading...',
    authMethod: '',
});

export default {
    props: {
        contractId: {
            type: String,
            required: true
        },
        requireRegenerate: {
            type: Boolean,
            required: false
        },
        dataLoading: {
            type: Boolean,
            required: false,
        }
    },

    data() {
        return {
            text: texts.default,
            error: false,
            userAuth: false,
            isConnected: false,
            regenerate: false,
        }
    },

    mounted() {
        this.authMethod = getAuthMethod();
        this.text = `Create ${this.authMethod}`
        if (isAccountAvailable()) {
            this.init();
        } else {
            onAccountAvailable(() => {
                this.init();
            });
        }
    },

    methods: {
        async init() {
            this.userAuth = await this.$isUserAuth();
            this.isConnected = true;
        },
        async $isUserAuth() {
            const contract = refContract(this.contractId);
            const key = await isUserAuthenticated(contract);
            return key;
        },
        async authenticateUser() {
            this.text = texts.loading;
            try {
                const contract = refContract(this.contractId);
                const res = await authenticateUser(contract,['owner'], 721)
                this.$emit('viewing-key-created');
                this.userAuth = this.$isUserAuth();
            } catch (e) {
                console.error(e)
                this.error = true;
            } finally {
                this.text = `Create ${this.authMethod}`;
                if(this.requireRegenerate){
                    this.regenerate = false;
                }
            }
        },
        bootstrap
    }
}
</script>

<style lang="scss" scoped>
.create-viewing-key-btn {
    small {
        margin-left: 12px;
        color: red;
    };
}

button {
    font-size: 16px;
    cursor: pointer;
    font-weight: 700;
    padding: 11px 0;
    line-height: 24px;
    border-radius: 8px;
    width: 164px;
}
</style>
