<template>
    <div class="my__emprops container">
        <div class="header">
            <article v-if="isShowSuccessMintAlert" class="success">
                <span> Congratulations! You have minted a token successfully.</span>
                <span class="success-close" @click="isShowSuccessMintAlert = false"> 
                    <img src="@/assets/icons/icon-close.svg" alt="icon-close"> 
                </span>
            </article>  
            <article v-if="isShowSuccessQuestionnaireAlert" class="success">
                <span> {{txMessaggeText}} </span>
                <span 
                    class="success-close" 
                    @click="isShowSuccessQuestionnaireAlert = false"
                >
                    <img src="@/assets/icons/icon-close.svg" alt="icon-close">
                </span>
            </article> 
            <div class="header__info">
                <div class="header__title">
                    <h1>My EmProps</h1>
                    <p 
                        class="header__description" 
                        v-if="isConnected && empropsAuth"
                    >
                        Total works owned: <strong>{{totalOwnerTokens}}</strong> 
                    </p>
                </div>
                <tab-navigation
                    class="tab__navigation"
                    :tabs="tabs"
                    @click="selectTab"
                >
                </tab-navigation>
            </div>
            <div class="filters">
                <h3>Tokens</h3>
                <div class="sort">
                    <div class="sort_by">
                        <div class="sort__dropdown">
                            <span>Project: {{ projectTitle }}</span>
                            <button @click="showProjectDropdown">
                                <img 
                                    src="@/assets/icons/chevron-down.svg" 
                                    alt="chevron down"
                                >
                            </button>
                        </div>
                        <div 
                            class="sort__dropdown__options" 
                            v-show="projectIsOpen"
                        >
                            <span 
                                v-for="project in currentProjects"
                                :key="project.id"
                                @click="getProject(project.id)"
                                :class="{selected: selectedProject(project)}"
                            > 
                                {{ project.title }}
                            </span>
                        </div>
                    </div>
                    <div class="sort_by">
                        <div class="sort__dropdown">
                            <span>Sort by: {{ sortedTitle }}</span>
                            <button @click="showSortByDropdown">
                                <img 
                                    src="@/assets/icons/chevron-down.svg" 
                                    alt="chevron down"
                                >
                            </button>
                        </div>
                        <div 
                            class="sort__dropdown__options" 
                            v-show="sortByIsOpen"
                        >
                            <span 
                                v-for="sort in sortData"
                                :key="sort.id"
                                @click="sortByDate(sort)"
                                :class="{ selected: sortedTitle === sort.title}"
                            > 
                                {{ sort.title }}
                            </span>
                        </div>
                    </div>
                    <input
                        type="text"
                        placeholder="Search by ID"
                        v-model.trim="searchTerm"
                        @keydown="searchArt"
                    />
                </div>
            </div>
        </div>
        <div>
            <div class="arts box" v-if="tabSelected == 'editions'">  
                <div class="minted_work" v-if="!empropsAuth">
                    <p class="header-description" v-if="!empropsAuth">
                        You need a {{ authMethod }} to view your purchased tokens
                    </p>
                    <button v-if="!empropsAuth || regenerateTokenVk && !artIsLoading" @click="authenticateEmprops">
                        {{labelButton}}
                    </button>
                </div>
                <button v-if="!isConnected" @click="bootstrap">CONNECT TO KEPLR</button>
                
                <div v-if="artIsLoading && isConnected">
                    <loading
                        text="Loading Your Tokens"
                    /> 
                </div>

                <div v-else>
                    <div class="tokens" v-if="empropsAuth">
                        <div v-for="art in arts" :key="art.id" class="token">
                            <router-link 
                                :to="{path: `/projects/${art.projectId}/art/${art.id}`}"
                            >
                                <img v-if="art.status == 'GENERATED'" 
                                    :src="art.previewImageUrl" :alt="'image #'"
                                >
                                <img v-else-if="art.status == 'RESERVED'" 
                                    class="icon" 
                                    :src="`${imagesUrl}/projects/${art.projectId}/placeholder_ungenerated.png`" 
                                    alt="Placeholder"
                                />
                            </router-link>

                            <div class="token-footer">
                                <div 
                                    class="token-footer-generated" 
                                    v-if="art.status == 'GENERATED'"
                                >
                                    <p>{{ projects[art.projectId] }} #{{ art.id % 1000000 }}</p>
                                    <div class="token-footer-generated-bottom">
                                        <div class="token-footer-generated-bottom-left">
                                            <router-link 
                                                :to="{ name: 'nft-details', 
                                                params: { projectId: projectId, artId: art.id } }"
                                            >token details
                                            </router-link>
                                            <a :href="`/projects/${projectId}/art/${art.id}/live`" 
                                                target="_blank"
                                            >
                                                live view
                                            </a>
                                        </div>
                                        <div class="token-footer-generated-bottom-right">
                                            <span v-if="art.generationEnabled"
                                                @click.prevent="regenerateToken(art.projectId, art.id)" 
                                            >
                                                regenerate token
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="token-footer-minted" v-else>
                                    <div class="token-footer-minted-top">
                                        <p>
                                            {{ projects[art.projectId] }} #{{ art.id % 1000000 }}
                                        </p>
                                        <span
                                            v-if="!project.requireQuestionnaire"
                                            @click.prevent="generateArt(art.id, art.projectId)"
                                        >
                                            generate token
                                        </span>
                                    </div>
                                    <div class="token-footer-minted-bottom">
                                        <span @click="openModalArtById(art.id, art.projectId)" v-if="project.requireQuestionnaire">
                                            {{ art.answered_questionnaire ? 'edit answers' : 'answer questionnaire'}}
                                        </span>
                                        <span
                                            v-if="art.answered_questionnaire"
                                            @click.prevent="generateArt(art.id, art.projectId)"
                                        >
                                            generate token
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="noTokens && !regenerateTokenVk" class="no-tokens">
                            <span>You don't own any Generative Editions yet</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="studies box" v-else>
                <create-viewing-key-btn
                    :requireRegenerate="regenerateStudyVk"
                    :contract-id="studies_id"
                    :data-loading="studiesIsLoading"
                    @viewing-key-created="createStudyVk"
                />
                <div v-if="studiesIsLoading">
                    <loading
                        text="Loading Your Studies"
                    /> 
                </div>
                <div v-else class="tokens">
                    <div v-for="study in studies" :key="study.id"  class="token">
                        <!-- Skip element if doesn't have the image in Media  -->
                        <router-link 
                            :to="{ 
                                name: 'study-details', 
                                params: { projectId: projectId, studyId: study.id }
                            }"
                        >
                            <img 
                                v-if="study.thumbnailImageUrl" 
                                :src="study.thumbnailImageUrl" 
                                :alt="`${studiesContractName} #${study.id}`"
                            >
                        </router-link>
                        <div class="token-footer" v-if="study.thumbnailImageUrl">
                            <div class="token-footer-generated-study">
                                <p>{{study.alias | doubleQuotes}}</p>
                                <router-link 
                                    :to="{ 
                                        name: 'study-details', 
                                        params: { projectId: projectId, studyId: study.id }
                                    }"
                                >
                                    token details
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="noStudies && !this.studiesIsLoading && !this.regenerateStudyVk">
                    <span>You don't have studies yet</span>
                </div>
            </div>		
        </div>

        <modal
            v-if="!artAnswered" 
            class="modal" 
            ref="QuestionareModal-1" 
            :closable="false" 
            :close-on-outside="false"
            close-on="dismiss-questionaire"
        >
            <div class="modal__content">
                <div class="modal__content__header">
                    <h3 class="modal__content__header__title"><b>The Big Bang</b></h3>
                    <h3>Private Collector Input</h3>
                </div>

                <div class="modal__content__body">
                    <div class="modal__content__body__instructions">
                        <p>
                            You have successfully minted a Big Bang generative NFT, and it currently exists in a privately sealed, un-generated state. It's up to you to take the final steps to define and generate this NFT. Before the Big Bang algorithm can work its magic, it needs you, the collector, to make a personal contribution to the artwork.
                        </p>
                        <p>
                            The Big Bang artwork comprises 13 defining traits. Three of the trait values are chosen by you, via the answers you provide on this questionnaire. When combined with your NFT's unique token seed, this is the only data that the Big Bang algorithm takes as its inputs.
                        </p>
                        <p>
                            There is no deadline or time limit to answer these questions. You can leave your Big Bang NFT in the un-generated state as long as you like. If you generate the token without answering the questions, values will be randomly chosen for you. 
                        </p>
                        <p>
                            We encourage you to discuss, debate, and share your questionnaire answers with other Big Bang collectors.
                        </p>
                    </div>
                    <div v-if="sendingAnswers && questionnaire.length > 1">
                        <loading 
                            text="Saving Your Answers"
                            position="center"
                        /> 
                    </div>
                    <form
                        v-else-if="questionnaire.length > 1"
                        class="modal__content__body__form" id="answer"
                    >
                        <h3>Choose your Big Bang color palette:</h3>
                        <div class="modal__content__body__form__options">
                            <label
                                v-for="(option, index) in questionnaire[0].possible_answers" 
                                :key="index"
                            >
                                <input 
                                type="radio" 
                                name="answer_one" 
                                :value="option"
                                v-model="answerOne"
                                >
                                <span>{{option | removeUnderScore}}</span>
                            </label>
                        </div>
                        <h3>How many rings in your Big Bang explosion?</h3>
                        <div class="modal__content__body__form__options select-in">
                            <select v-model="answerTwo">
                                <option disabled value="">SELECT ONE</option>
                                <option  
                                v-for="(option, index) in questionnaire[1].possible_answers" 
                                :key="index"
                                :value="option"
                                >
                                {{option}}
                                </option>
                            </select>
                        </div>
                        <h3>How many spikes in your Big Bang explosion?</h3>
                        <div class="modal__content__body__form__options select-in">
                            <select v-model="answerThree">
                                <option disabled value="">SELECT ONE</option>
                                <option  
                                v-for="(option, index) in questionnaire[2].possible_answers" 
                                :key="index"
                                :value="option"
                                >
                                {{option}}
                                </option>
                            </select>
                        </div>
                        <div class="modal__content__body__form__note">
                            <p>* This determines the number of spikes on the innermost ring of the explosion. If your token has the rare trait "Chaos = Yes" the number of spikes will increase substantially.</p>
                        </div>
                    </form>
                </div>

                <div class="modal__content__footer">
                    <button 
                        type="button"
                        class="btn btn--secondary" 
                        @click="closeModal" 
                        :disabled="sendingAnswers" 
                    >
                        CANCEL
                    </button>
                    <button 
                        type="submit"
                        class="btn btn--primary"
                        @click="sendAnswersForm"
                        :disabled="!answerOne || !answerTwo || !answerThree || sendingAnswers"
                    >
                        CONFIRM
                    </button >
                </div>
            </div>
        </modal>

        <modal
            v-else 
            class="modal" 
            ref="QuestionareModal-1" 
            :closable="false" 
            :close-on-outside="false"
            close-on="dismiss-questionaire"
        >
            <div class="modal__content">
                <div class="modal__content__header">
                    <h3 class="modal__content__header__title"><b>The Big Bang</b></h3>
                    <h3>Private Collector Input</h3>
                </div>

                <div class="modal__content__body">
                    <div class="modal__content__body__instructions">
                        <p>
                            You have successfully minted a Big Bang generative NFT, and it currently exists in a privately sealed, un-generated state. It's up to you to take the final steps to define and generate this NFT. Before the Big Bang algorithm can work its magic, it needs you, the collector, to make a personal contribution to the artwork.
                        </p>
                        <p>
                            The Big Bang artwork comprises 13 defining traits. Three of the trait values are chosen by you, via the answers you provide on this questionnaire. When combined with your NFT's unique token seed, this is the only data that the Big Bang algorithm takes as its inputs.
                        </p>
                        <p>
                            There is no deadline or time limit to answer these questions. You can leave your Big Bang NFT in the un-generated state as long as you like. If you generate the token without answering the questions, values will be randomly chosen for you. 
                        </p>
                        <p>
                            We encourage you to discuss, debate, and share your questionnaire answers with other Big Bang collectors.
                        </p>
                    </div>
                    <div v-if="sendingAnswers && questionnaire.length > 1">
                        <loading 
                            text="Saving Your Answers"
                            position="center"
                        /> 
                        </div>
                        <form 
                            v-else-if="questionnaire.length > 1"
                            class="modal__content__body__form" id="answer"
                        >
                            <h3>Choose your Big Bang color palette:</h3>
                            <div class="modal__content__body__form__options">
                            <label
                                v-for="(option, index) in questionnaire[0].possible_answers" 
                                :key="index"
                            >
                                <input 
                                type="radio" 
                                name="answer_one" 
                                :value="option"
                                v-model="answerOne"
                            >
                            <span>{{option | removeUnderScore}}</span>
                            </label>
                            </div>
                            <h3>How many rings in your Big Bang explosion?</h3>
                            <div class="modal__content__body__form__options select-in">
                                <select v-model="answerTwo">
                                    <option disabled value="">SELECT ONE</option>
                                    <option  
                                    v-for="(option, index) in questionnaire[1].possible_answers" 
                                    :key="index"
                                    :value="option"
                                    >
                                    {{option}}
                                    </option>
                                </select>
                            </div>
                            <h3>How many spikes in your Big Bang explosion?</h3>
                            <div class="modal__content__body__form__options select-in">
                            <select v-model="answerThree">
                                <option disabled value="">SELECT ONE</option>
                                <option  
                                v-for="(option, index) in questionnaire[2].possible_answers" 
                                :key="index"
                                :value="option"
                                >
                                {{option}}
                                </option>
                            </select>
                            </div>
                            <div class="modal__content__body__form__note">
                                <p>* This determines the number of spikes on the innermost ring of the explosion. If your token has the rare trait "Chaos = Yes" the number of spikes will increase substantially.</p>
                            </div>
                        </form>
                </div>

                <div class="modal__content__footer">
                    <button 
                        type="button"
                        class="btn btn--secondary"
                        @click="closeModal"
                        :disabled="sendingAnswers" 
                    >CANCEL
                    </button>
                    <button 
                        type="submit"
                        class="btn btn--primary"
                        @click="sendAnswersForm"
                        :disabled="!answerOne || !answerTwo || !answerThree || sendingAnswers"
                    >CONFIRM
                    </button >
                </div>
            </div>
        </modal>

        <modal
            v-if="!artAnswered" 
            class="modal" 
            ref="QuestionareModal-2" 
            :closable="false" 
            :close-on-outside="false"
            close-on="dismiss-questionaire"
        >
            <div class="modal__content">
                <div class="modal__content__header">
                    <h3 class="modal__content__header__title"><b>Resilient [пружний]</b></h3>
                    <h3>Private Collector Input</h3>
                </div>

                <div class="modal__content__body">
                    <div class="modal__content__body__instructions">
                        <p>
                            Thank you for supporting Ukraine humanitarian efforts through your purchase.
                        </p>
                        <p>
                            You have successfully minted a RESILIENT [пружний] NFT, and it currently exists in a privately sealed, un-generated state.
                        </p>
                        <p>
                            It's now up to you to take the final step to define and generate this NFT. Before the RESILIENT [пружний] algorithm runs, it needs you, the collector, to make one final contribution to the artwork.
                        </p>
                        <p>
                            Please choose one of the 16 possible background colors shown to the right. Along with your NFT's unique, privately sealed token seed, your choice of background color is the only data the algorithm takes as its inputs.
                        </p>
                        <p>
                            There is no deadline or time limit to answer this questionnaire. You can leave your RESILIENT [пружний] token in the un-generated state as long as you like. We encourage you to discuss, debate, and share your background color choice with other RESILIENT [пружний] collectors.
                        </p>
                    </div>
                    <div v-if="sendingAnswers && questionnaire.length > 0">
                        <loading 
                            text="Saving Your Answers"
                            position="center"
                        /> 
                    </div>
                    <form
                        v-else-if="questionnaire.length > 0"
                        class="modal__content__body__form" id="answer"
                    >
                        <h3>Choose your Background Color:</h3>
                        <div class="modal__content__body__form__options">
                            <label
                                v-for="(option, index) in questionnaire[0].possible_answers" 
                                :key="index"
                            >
                                <input 
                                  type="radio" 
                                  name="answer_one" 
                                  :value="option"
                                  v-model="answerOne"
                                >
                                <span
                                    class="label"
                                    :class="option.toLowerCase().replace(' ', '_')"
                                >
                                    {{option | removeUnderScore}}
                                </span>
                            </label>
                        </div>
                    </form>
                </div>

                <div class="modal__content__footer">
                    <button 
                        type="button"
                        class="btn btn--secondary" 
                        @click="closeModal" 
                        :disabled="sendingAnswers" 
                    >
                        CANCEL
                    </button>
                    <button 
                        type="submit"
                        class="btn btn--primary"
                        @click="sendAnswersForm"
                        :disabled="!answerOne || sendingAnswers"
                    >
                        CONFIRM
                    </button >
                </div>
            </div>
        </modal>

        <modal
            v-else 
            class="modal" 
            ref="QuestionareModal-2" 
            :closable="false" 
            :close-on-outside="false"
            close-on="dismiss-questionaire"
        >
            <div class="modal__content">
                <div class="modal__content__header">
                    <h3 class="modal__content__header__title"><b>Resilient [пружний]</b></h3>
                    <h3>Private Collector Input</h3>
                </div>

                <div class="modal__content__body">
                    <div class="modal__content__body__instructions">
                       <p>
                            Thank you for supporting Ukraine humanitarian efforts through your purchase.
                        </p>
                        <p>
                            You have successfully minted a RESILIENT [пружний] NFT, and it currently exists in a privately sealed, un-generated state.
                        </p>
                        <p>
                            It's now up to you to take the final step to define and generate this NFT. Before the RESILIENT [пружний] algorithm runs, it needs you, the collector, to make one final contribution to the artwork.
                        </p>
                        <p>
                            Please choose one of the 16 possible background colors shown to the right. Along with your NFT's unique, privately sealed token seed, your choice of background color is the only data the algorithm takes as its inputs.
                        </p>
                        <p>
                            There is no deadline or time limit to answer this questionnaire. You can leave your RESILIENT [пружний] token in the un-generated state as long as you like. We encourage you to discuss, debate, and share your background color choice with other RESILIENT [пружний] collectors.
                        </p>
                    </div>
                    <div v-if="sendingAnswers && questionnaire.length > 0">
                        <loading 
                            text="Saving Your Answers"
                            position="center"
                        /> 
                    </div>
                    <form 
                        v-else-if="questionnaire.length > 0"
                        class="modal__content__body__form" id="answer"
                    > 
                        <h3>Choose your Background Color:</h3>
                        <div class="modal__content__body__form__options">
                          <label
                              v-for="(option, index) in questionnaire[0].possible_answers" 
                              :key="index"
                          >
                              <input 
                              type="radio" 
                              name="answer_one" 
                              :value="option"
                              v-model="answerOne"
                          >
                            <span
                                class="label"
                                :class="option.toLowerCase().replace(' ', '_')"
                            >
                                {{option | removeUnderScore}}
                            </span>
                          </label>
                        </div>
                    </form>
                </div>

                <div class="modal__content__footer">
                    <button 
                        type="button"
                        class="btn btn--secondary"
                        @click="closeModal"
                        :disabled="sendingAnswers" 
                    >CANCEL
                    </button>
                    <button 
                        type="submit"
                        class="btn btn--primary"
                        @click="sendAnswersForm"
                        :disabled="!answerOne || sendingAnswers"
                    >CONFIRM
                    </button >
                </div>
            </div>
        </modal>

        <modal
            ref="generateArtModal"
            :closable="false"
            :close-on-outside="false"
            close-on="dismiss-generate-art-modal"
            width="600px"
            padding="24px"
        >
            <div class="modal-body">
                <div v-if="showTxError" class="error">
                    <span>Transaction failed, please try again.</span>
                </div>
                <div v-else>
                    <loading text="Generating Art"></loading>
                    <iframe  
                        id="iframe" 
                        v-show="showPreviewArt" 
                        :class="{showIframe: showPreviewArt}">
                    </iframe>                                        
                </div>
            </div>
        </modal>
    </div>
</template>

<script>

import { bech32, getAddress, onAccountAvailable, bootstrap, refContract, isAccountAvailable } from '@stakeordie/griptape.js';
import { 
    isUserAuthenticated, 
    authenticateUser, 
    getAuthMethod,
    initContracts 
} from '@/utils.js'
import api from '@/service/api';
import Modal from "@/components/Modal";
import CreateViewingKeyBtn from '@/components/CreateViewingKeyButton';
import Loading from '@/components/Loading.vue';
import ThumbnailTest from '@/components/ThumbnailTest.vue';
import TabNavigation from '@/components/UiUtils/TabNavigation/TabNavigation.vue';
import axios from 'axios';

const imagesUrl = process.env.VUE_APP_IMAGES_URL;

const debounce = function (func, timeout = 500) {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export default {
    name: 'MyCollection',
    components: {
        Modal,
        CreateViewingKeyBtn,
        Loading,
        ThumbnailTest,
        TabNavigation
    },

    data() {
        return {
            currentProjects: [],
            projectSelected: [],
            project: null,
            userTokenId: "",
            empropsAuth: true,
            tokensList: [],
            arts: [],
            artIsLoading: false,
            contractInfo: null,
            studies_id: process.env.VUE_APP_STUDIES_CONTRACT,
            projectId: null,
            imagesUrl,
            isSuccess: false,
            labelButton: '',
            isLoading: true,
            pjsCode: null,
            authMethod:'',
            //Questionare
            questionsIsLoading: false,
            questionnaire: [{id: ''},{id: ''},{id: ''}],
            answerOne: '',
            answerTwo: '',
            answerThree: '',
            errors: [],
            artId: null,
            artAnswered: false,
            showTxMessagge: false,
            txMessaggeText: null,
            openModalText: 'Take',
            showTxError: false,
            projects: {},
            sendingAnswers: false,
            noStudies:false,
            studies: [],
            studiesContractName: '',
            studiesIsLoading: false,
            isConnected: false,
            isShowSuccessMintAlert: false,
            isShowSuccessQuestionnaireAlert: false,

            address: '',

            tabs:['editions', 'studies'],
            tabSelected: 'editions',

            regenerateTokenVk: false,
            regenerateStudyVk: false,
            projectIsOpen: false,
            sortByIsOpen: false,
            projectTitle: '',
            sortedTitle: 'date aquired (newest first)',
            sortData: [{id: 1, title: "date aquired (newest first)"},{id: 2, title: "date aquired (oldest first)"}],
            searchTerm: '',
            showPreviewArt: false,
            imagesUrl: process.env.VUE_APP_IMAGES_URL
        }
    },
    filters: {
        doubleQuotes: function (value) {
            if (!value) return ''
            return value.replaceAll("'",'"')
        },
        removeUnderScore: function(value){
            if (!value) return ''
            return value.replaceAll("_",' ')
        },
    },
    async mounted() {
        await this.getAllProjects();
        await this.getProject(this.$route.params.projectId);
        await initContracts();
        this.authMethod = getAuthMethod();
        this.labelButton = `Create ${this.authMethod}`;
        if (isAccountAvailable()) {
            this.init();
        } else {
            onAccountAvailable(() => {
                this.init();
            });
        }
        this.showSuccessMessage();
        await this.getAllQuestions();
        const contractAddress = process.env.VUE_APP_STUDIES_CONTRACT;
        const studiesContract = refContract(contractAddress);
        const { contract_info: { name }} = await studiesContract.getContractInfo();
        this.studiesContractName = name;
        this.isOrderedFirstToLast = true;
    },
    computed: {
        noTokens() {
            return !this.artIsLoading && this.arts.length === 0 && this.isConnected;
        },
        totalOwnerTokens () {
            if(!this.artIsLoading && !this.studiesIsLoading) {
                if(this.tabSelected === "editions") {
                    return this.tokensList.length;
                }
                else{
                    return this.studies.length;
                } 
            }
        },
    },
    methods: {
        async init() {
            this.isConnected = true;
            this.address = getAddress();
            const projects = await api.projects.getAll();
            this.userTokenId = bech32(getAddress(), 16);

            for (const project of projects) {
                this.$set(this.projects, project.id, project.title);
            }
            await this.isEmpropsAuth();
            await this.isStudiesAuth();
            await this.updateArt();
            await this.getP5jsCode();
        },
        closeGenerateModal(){
            this.$refs.generateArtModal.hideModal();
        },
        fromBase64(str) {
            return decodeURIComponent(escape(window.atob(str)));
        },
        async isStudiesAuth() {
            const studiesContract = refContract(process.env.VUE_APP_STUDIES_CONTRACT);
            const studiesAuth = await isUserAuthenticated(studiesContract);
            if (!studiesAuth) return;

            await this.getStudies();
            this.noStudies = !this.studiesIsLoading && this.studies.length === 0
        },
        async renderingArt(artId) {
            // Get token data
            const contract = refContract(this.project.snip721ContractAddress);
            const { nft_dossier } = await contract.getNftDossier(artId);
            const { private_metadata, seed } = nft_dossier;
            //get questionnaire answers
            const answers = {};
            for (const item of private_metadata.extension.attributes) {
                answers[item.trait_type] = item.value;
            }

            //script generation
            const textToReplace = "<!-- FOR MINTING ONLY, DO NOT MODIFY -->";
            const size = 1000;
            const sizeScript = `if(!window.document.location.search) { window.document.location.search = '?size=${size}'; }`
            const getTokenInfoScript = `<script> ${sizeScript} function getTokenInfo(projectNumber) { return { answers: { "Color Palette": '${answers['Color Palette']}', "Number of Rings": ${parseFloat(answers['Number of Rings'])}, "Genesis Ring Points": ${parseFloat(answers['Genesis Ring Points'])} }, hash: '${seed}', tokenId: ${artId}}}<\/script>`;

            // Get P5.js art script
            const { code: { code } } = await contract.getP5jsCode();

            //decode from base 64 and injecting js into onchain HTML
            const html = this.fromBase64(code).replace(textToReplace, getTokenInfoScript)
            
            const iframe = document.getElementById('iframe');
            iframe.srcdoc = html;

            setTimeout(async () => {
              const canvas = iframe.contentDocument.getElementById('defaultCanvas0')
              const image = canvas.toDataURL()
              const command = {
                artId,
                previewImage: image,
                thumbnailImage: image
              };
              await api.projects.arts.generateArt(this.projectId, artId, command);
              this.$refs.generateArtModal.hideModal();
              await this.getTokens();
            }, 15000);
        },
        async renderingArtWithTraits(artId, projectId){
            // Get token data
            const contract = refContract(this.project.snip721ContractAddress);
            const { nft_dossier } = await contract.getNftDossier(artId);
            const { public_metadata, seed } = nft_dossier;
            const attributes = public_metadata.extension.attributes;
            const $attributes = {};

            const traits = attributes.map((trait) => ({
                name: trait.trait_type,
                value: trait.value
            }));

            for (const attr of attributes) {
                $attributes[attr.trait_type] = attr.value;         
            } 

            const $answers = Object.keys($attributes)
            .map(key => {
                let value = $attributes[key]; 
                if (!isNaN(value)) {
                    value = parseInt(value);
                } else {
                    value = `"${value}"`; 
                }  
                return `"${key}":${value}`;
            })
            .join(",");
            const $features = $answers;

            const template = `<script>function getTokenInfo() {return { answers: {${$answers}},features: {${$features}},hash: "${seed}",tokenId: "${artId}"}}<\/script>`;

            //script generation
            const textToReplace = "<!-- FOR MINTING ONLY, DO NOT MODIFY -->";
            

            // Get P5.js art script
            const { code: { code } } = await contract.getP5jsCode();

            //decode from base 64 and injecting js into onchain HTML
            const html = this.fromBase64(code).replace(textToReplace, template);
            

            //Apartir de esta linea....
            const iframe = document.getElementById('iframe');
            iframe.srcdoc = html;

            setTimeout(async () => {
              const canvas = iframe.contentDocument.getElementById('defaultCanvas0')
              const image = canvas.toDataURL()
              const command = {
                artId,
                previewImage: image,
                thumbnailImage: image
              };
              const traitsCommand = {
                artId: artId,
                traits
              };
              await api.projects.arts.generateArt(projectId, artId, command);
              /* await api.projects.arts.traits.saveTraits(projectId, artId, traitsCommand); */
              this.$refs.generateArtModal.hideModal();
              await this.getTokens();
            }, 5000);
        },
        async renderingResilient(artId, projectId){
            const contract = refContract(this.project.snip721ContractAddress);
            const { nft_dossier } = await contract.getNftDossier(artId);
            const { seed, private_metadata } = nft_dossier;
            const answers = {};

            for (const item of private_metadata.extension.attributes) {
                answers[item.trait_type] = item.value;
            }

            const template = `<script>function getTokenInfo() { return { seed: "${seed}", answers: { background: "${answers.background}" } } }<\/script>`;
            const textToReplace = "<!-- FOR MINTING ONLY, DO NOT MODIFY -->";

            // Get P5.js art script
            const { code: { code } } = await contract.getP5jsCode();
            if(code.startsWith('https://')) {
                this.showPreviewArt = true;
                const htmlResponse = await axios.get(code);
                const html = htmlResponse.data.replace(textToReplace, template);

                const iframe = document.getElementById('iframe');
                iframe.srcdoc = html;

                const time = Math.floor(Math.random() * 10);

                setTimeout(async () => {
                    if (iframe.contentWindow.takeScreenshot) {
                        const image = iframe.contentWindow.takeScreenshot();
                        const command = {
                                artId,
                                previewImage: image,
                                thumbnailImage: image
                        };
                        await api.projects.arts.generateArt(projectId, artId, command);
                        this.$refs.generateArtModal.hideModal();
                        this.showPreviewArt = false;
                        await this.getTokens();
                    }
                }, 15000 + time);
            }   
        },
        async updateArt(){
            try {
                if (this.empropsAuth) {
                    const res = this.tokensList.filter(async tokenId => {
                        const exists = this.arts.find((art)=> art.id == tokenId);
                        if(!exists){
                            return await this.reserveNft(tokenId)
                        }
                    });
                    await Promise.all(res);
                }
            } catch (error) {
                console.error(error)
            }
        },
        async isEmpropsAuth() {
          const contract = refContract(this.project.snip721ContractAddress);  
          this.empropsAuth = await isUserAuthenticated(contract);
          if(!this.empropsAuth) return;
          
          await this.getTokens();
        },
        async getAllProjects() {
            this.currentProjects = await api.projects.getAll();
        },
        async getProject(projectId) {
            this.projectIsOpen = false;
            this.$router.push({name: 'myemprops', params: { projectId }});
            this.project = await api.projects.get(projectId);
            this.projectId = projectId;
            this.isEmpropsAuth();
        },
        async getTokens() {
            this.artIsLoading = true;
            try {
                if (this.empropsAuth) {
                    const contract = refContract(this.project.snip721ContractAddress);
                    const { token_list } = await contract.getTokens();
                    const { tokens } = token_list;
                    this.tokensList = tokens;
    
                    const { content } = await api.projects.arts.search( this.projectId, this.searchTerm, 1024, undefined);
                    this.arts = content.filter(res => this.tokensList.includes(res.id));
                    this.arts = await Promise.all(this.arts.map( async (it) => {
                      if(it.status == "GENERATED"){ return it };  
                      const { has_answered } = await contract.getHasAnswered(it.id);
                      return { 
                        ...it,
                        ...has_answered 
                      };
                    }));
                    
                    this.arts = this.arts.sort((a, b) => (a.id > b.id) ? 1 : -1);
                }
            }catch (error) {
                let vkError = error.toString().includes('Wrong viewing key'); 
                if (vkError) {
                    this.regenerateTokenVk = true;
                }
            }finally {
                this.artIsLoading = false;
            }
        },
        async authenticateEmprops() {
            this.isLoading = true;
            this.labelButton = 'Loading...';
            try {
                const contract = refContract(this.project.snip721ContractAddress);
                await authenticateUser(contract, ["owner"], 721);
            } finally {
              this.isLoading = false;
              this.labelButton = `Create ${this.authMethod}`;
              if(this.regenerateTokenVk){
                this.regenerateTokenVk = false;
              }
            }
            this.isEmpropsAuth();
        },
        showSuccessMessage() {
            const query = this.$route.query;
            if (query.success) {
                this.isShowSuccessMintAlert = true;
                /* this.isSuccess = true; */
                /* setTimeout(() => this.isSuccess = false, 5000); */
            } else {
                this.isShowSuccessMintAlert = false;
            }
        },
        async reserveNft(tokenId) {
            const command = {
                projectId: this.projectId,
                artId: tokenId,
            };
            try {
                await api.projects.arts.reserve(this.projectId, command);
            } catch (error) {
                if (error.error == 'total_mints') {
                    await this.$router.push({name: 'project', params: { projectId: this.projectId }});
                    return;
                }
            }
        },
        async getP5jsCode() {
          const contract = refContract(this.project.snip721ContractAddress);
          const codeResponse = await contract.getP5jsCode();
          const { code } = codeResponse;
          this.pjsCode = code;
        },
        async getAllQuestions() {
          this.questionsIsLoading = true;
          const contract = refContract(this.project.snip721ContractAddress);
          try {
            const questionsResponse = await contract.getQuestions();
            const { questionnaire } = questionsResponse;
            if(questionnaire.questionnaire.length) {
                this.questionnaire = questionnaire?.questionnaire;
            }
          } catch (error) {
            console.error(error);
          } finally {
            this.questionsIsLoading = false;
          }
        },
        async answerQuestionnaire(artId, answers) {
          const contract = refContract(this.project.snip721ContractAddress);
          this.sendingAnswers = true;
          try {
            await contract.answerQuestionnare(artId, answers)
            await this.getTokens();
            this.isShowSuccessQuestionnaireAlert = true;
            this.txMessaggeText = 'Questionnaire Answered Successfully!'
          } catch(error) {
            console.error('Error', error);
            this.sendingAnswers = false;
          } finally {
            this.$refs[`QuestionareModal-${this.projectId}`].hideModal();
            this.sendingAnswers = false;
            /* setTimeout(() => this.showTxMessagge = false, 5000); */
          }
        },
        sendAnswersForm(e) {
          e.preventDefault();
          const artId = this.artId;
          const answers = [];
          if(this.projectId === '1') {
            answers.push(
              { question_id: this.questionnaire[0].id, answer: this.answerOne },
              { question_id: this.questionnaire[1].id, answer: this.answerTwo },
              { question_id: this.questionnaire[2].id, answer: this.answerThree }
            );
          }else if(this.projectId === '2') {
            answers.push(
              { question_id: this.questionnaire[0].id, answer: this.answerOne }
            )
          }
          this.answerQuestionnaire(artId, answers);
        },
        openModalArtById(id, projectId) {
          this.artId = id;
          let selectedArt = this.arts.filter(el =>  el.id === id);
          if(selectedArt[0].answered_questionnaire) {
            this.artAnswered = true;
            this.NFTinfo(id);
          }else {
            this.artAnswered = false;
          }
          this.$refs[`QuestionareModal-${projectId}`].showModal();
        },
        closeModal() {
          this.answerOne = '';
          this.answerTwo = '';
          this.answerThree = '';
          this.$root.$emit('dismiss-questionaire');
        },
        async NFTinfo (artId) {
          const contract = refContract(this.project.snip721ContractAddress);  
          const response = await contract.getNftDossier(artId);
          const attributes = response?.nft_dossier?.private_metadata?.extension?.attributes;
          if(this.projectId === '1') {
            this.answerOne = attributes[0].value;
            this.answerTwo = attributes[1].value;
            this.answerThree = attributes[2].value;
          } else if (this.projectId === '2') {
            this.answerOne = attributes[0].value;
          }
        },
        async generateArt(artId, projectId) {
            const contract = refContract(this.project.snip721ContractAddress);  
            // Show the loading modal
            this.$refs.generateArtModal.showModal();

            try {
                // Call the `generate_art` message
                const { is_generated } = await contract.getIsGenerated(artId);

                if(!is_generated.value){
                    let result = await contract.generateArt(artId);

                    if (result.isEmpty()) {
                    throw new Error('generate_art: Result is empty');
                    }

                    const res = result.parse();
                    if (!res.set_metadata.status === 'success') {
                    throw new Error('generate_art: status was not `success`');
                    }
                }

                if(projectId == '1'){
                    await this.renderingArt(artId);
                } else {
                    await this.renderingResilient(artId, projectId);
                }

            } catch (e) {
                console.error(e)
                if (e.toString().match(/Reject/ig)) {
                    this.$refs.generateArtModal.hideModal();
                } else {
                    this.showTxError = true;
                }
            }
        },
        async getStudies() {
            const studiesContract = refContract(process.env.VUE_APP_STUDIES_CONTRACT)
            const isStudiesAuth = await isUserAuthenticated(studiesContract);
            if (!isStudiesAuth) return;
            this.studiesIsLoading = true;
            try {
                const all_tokens = await studiesContract.getTokens();
                const tokens = all_tokens.token_list.tokens;
                const AllstudiesIds = tokens.join(',');
                const res = await api.projects.studies.getStudiesByIds(this.projectId, AllstudiesIds || "no_ids");
                this.studies = res.content;
            }catch( error ) {
                console.error(error);
                let vkError = error.toString().includes('Wrong viewing key'); 
                if (vkError) {
                    this.regenerateStudyVk = true;
                }
            }finally {
                this.studiesIsLoading = false;
                this.noStudies = !this.studiesIsLoading && this.studies.length === 0 && isStudiesAuth;
            }
        },
        async regenerateToken(projectId, artId) {
          const generationEnabledCommand = { generationEnabled: false };
          try {
            await this.generateArt(artId);
            await api.projects.arts.enableGeneration(projectId, artId, generationEnabledCommand);
          } catch(error) {
            console.error(error);
          }
        },
        async selectTab(tabSelected) {
            let contract;
            this.tabSelected = tabSelected;
            
            if(!this.isConnected) return;

            this.isConnected = true;

            if(tabSelected == 'editions') {
                contract = refContract(this.project.snip721ContractAddress);
                this.getTokens();
            } else {
                contract = refContract(process.env.VUE_APP_STUDIES_CONTRACT);
                this.getStudies();
            }
            this.empropsAuth = await isUserAuthenticated(contract);
        },
        async createStudyVk(regenerate){
            const studyContract = refContract(process.env.VUE_APP_STUDIES_CONTRACT);
            this.empropsAuth = await isUserAuthenticated(studyContract);
            this.regenerateStudyVk = regenerate;
            this.getStudies();
        },
        showProjectDropdown() {
            this.projectIsOpen = !this.projectIsOpen;
        },
        showSortByDropdown() {
            this.sortByIsOpen = !this.sortByIsOpen;
        },
        selectedProject(project) {
            if(project.id === this.$route.params.projectId){
                this.projectTitle = project.title;
                return true;
            }
        },
        sortByDate(sort) {
            this.sortByIsOpen = false;
            if(sort.id === this.sortData[0].id) {
                this.arts.sort((a, b) => (a.id > b.id) ? 1 : -1);
            } else {
                this.arts.sort((a, b) => (a.id < b.id) ? 1 : -1);
            }
            this.sortedTitle = sort.title;
        },
        searchArt: debounce(async function (event) {
            if (event.keyCode == 13) {
                event.target.blur();
            }
            await this.getTokens();
        }, 250),
        bootstrap
    }
}
</script>

<style lang="scss" scoped>
.my__emprops {
    display: grid;
    grid-template-rows: (3, 1fr);
    row-gap: 16px;
    &.container {
        max-width: 1200px;
        margin: 42px auto;
    }
    .box {
        padding: 16px;
    }
    .minted_work {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        background-color: var(--color-yellow);
        
        p{
            margin-bottom: 0;
        }

    }
    button {
        font-size: 16px;
        cursor: pointer;
        font-weight: 700;
        padding: 11px 0;
        line-height: 24px;
        border-radius: 8px;
        width: 164px;
        margin: 0px;
    }
    .header{
        display: flex;
        flex-direction: column;
        gap: 32px;
        padding: 16px;
        
        &__info {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &__title {
            display: flex;
            align-items: center;
            gap: 26px;
        }
        h1 {
            font-style: italic;
            margin-bottom: 0px;
            line-height: 46px;
        }
        &__description {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin: 0px;
            strong  {
                margin-left: 8px;
            }
        }
        button {
            font-size: 16px;
            cursor: pointer;
            font-weight: 700;
            padding: 11px 20px;
            line-height: 24px;
            border-radius: 8px;
            width: 164px;
        }
        .success {
            display: flex;
            justify-content: space-between;
            padding: 7px;
            background-color: #589E5F;
            margin-top: 16px;
            span  {
                color: var(--color-neutral-white);
                line-height: 24px;
            }
            &:last-child {
            margin-bottom: 0;
            }
            &-close {
                cursor: pointer;
                margin-right: 10px;
            }
        }
    }
    .filters {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding: 0 ;

        h3{
            margin-bottom: 0px;
        }

        .sort {
            display: flex;
            column-gap: 42px;
            .sort_by {
                display: block;
                margin: auto 0;
            }
            .sort__dropdown {
                display: flex;
                align-items: center;
                column-gap: 8px;
                button {
                    all: unset;
                    cursor: pointer;
                }
                &__options {
                    background-color: var(--color-neutral-black);
                    width: 176px;
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    top: 46px;
                    span {
                        cursor: pointer;
                        padding: 8px;
                        color: var(--color-neutral-white);
                        line-height: 24px;
                        &:hover {
                            background-color: var(--color-emerald);
                            transition: 0.2s;
                        }
                        &.selected {
                            background-color: var(--color-emerald);
                        }
                    }
                }
            }
        }
        input {
            border: 1px solid #000000;
            box-sizing: border-box;
            border-radius: 4px;
            background-image: url(../assets/icons/search.svg);
            background-repeat: no-repeat;
            background-position: 16px 11px;
            text-indent: 36px;
            width: 184px;
            height: 46px;
            padding: 4px 16px;
            color: #808080;
            margin: 0px;
        }
    }
    .tokens {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto;
        grid-gap: 16px;
        row-gap: 26px;
        .token {
            display: inline-block;
            img {
                width: 100%;
                height: auto;
            }
            .token-footer {
                width: 100%;
                margin-top: 16px;
                background-color: transparent;
                &-generated {
                display: flex;
                flex-direction: column;
                row-gap: 8px;
                &-bottom {
                    display: flex;
                    column-gap: 8px;
                    align-items: center;
                    justify-content: space-between;
                    &-left {
                        display: flex;
                        column-gap: 8px;
                    }
                }
                &-study {
                    display: flex;
                    flex-direction: column;
                    row-gap: 8px;
                }
                }
                &-minted {
                    display: flex;
                    flex-direction: column;
                    row-gap: 8px;
                    &-bottom,
                    &-top  {
                        display: flex;
                        justify-content: space-between;
                    }
                }
                p {
                    width: auto;
                    margin: 0px;
                    line-height: 24px;
                }
                a {
                    color: var(--color-emerald);
                    text-decoration: none;
                    font-weight: 400;
                    text-transform: lowercase;
                    line-height: 18px;
                }
                span {
                cursor: pointer;
                color: var(--color-emerald);
                line-height: 18px;
                font-weight: 400;
                }
            }
        }
        .no-tokens {
            margin: 16px 0px;
        }
    }
}

.modal {
  &__content {
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-row-gap: 48px;
    width: 1084px;
    padding: 48px;
    overflow: auto;
    &__header {
      h3 {
        text-align: left;
        margin: 0px;
        line-height: 33px;
        font-size: 24px;
        margin-bottom: 10px;
        &:last-child{
            margin-bottom: 0px;
        }
      }
      p{
        margin: 0px;
        text-align: left;
      }
    }
    &__body {
      display: grid;
      width: 100%;
      grid-template-columns: 392px 1fr;
      column-gap: 48px;
      &__instructions {
        p {
          text-align: left;
          color: var(--color-neutral-02);
          margin-bottom: 10px;
          line-height: 24px;
          font-weight: 400;
        }
        .strong {
          p {
            font-weight: 700;
            color: var(--color-neutral-02);
            text-align: justify;
            line-height: 24px;
            margin-bottom: 0px;
          }
        }
      }

      &__form {
        h3 {
          text-align: left;
          line-height: 33px;
          margin-bottom: 10px;
        }
        &__options {
          display: flex;
          flex-wrap: wrap;
          gap: 16px;
          margin-bottom: 20px;
          label {
            margin: 0px;
          }
        }
        &__note {
            p {
                color: var(--color-neutral-02);
                font-size: 12px;
                line-height: 14px;
                margin: 0px;
                text-align: justify;
            }
        } 
      }
      #answer input[type="radio"] {
        display: none;
      }
      #answer span {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        display: inline-block;
        text-transform: uppercase;
        border: 0.5px solid #000000;
        border-radius: 8px;
        color: var(--color-neutral-02);
        cursor: pointer;
        height: 48px;
        width: 170px;
        display: grid;
        place-content: center center;
      }
      #answer input[type="radio"]:checked + span {
        background-color: var(--color-neutral-black);
        color: var(--color-neutral-white);
        border: 0.5px solid #000000;
      }
      #answer input[type="select"] {
        background-color: red;
      }
      .select-in select {
        background: transparent;
        margin: 0px;
        border: 0.5px solid #000000;
        border-radius: 4px;
        padding: 12px 16px;
        color: var(--color-neutral-02);
        line-height: 24px;
        font-weight: 700;
        text-transform: capitalize;
        cursor: pointer;
        &:focus{ outline: none;}
      }
    }
    &__footer {
      display: flex;
      justify-content: flex-end;
      gap: 9px;
      .btn {
        margin: 0px;
        border-radius: 8px;
        padding: 11px 20px;
        width: 102px;
        height: 46px;
        line-height: 24px;
        font-weight: 700;
        font-size: 16px;
        &--secondary {
          background-color: white;
          border: 1px solid black;
          color: black;
        }
        &--primary {
          border: 1px solid black;
          &:disabled {
            cursor: unset;
          }
        }
      }
    }
  }
}
@include respond-to("medium and down") {
  .modal {
    &__content {
      width: calc(100vw - 20px);
      padding: 48px;
      grid-row-gap:14px;
      @include respond-to("small and down") {
        height: calc(100vh - 20px);
        padding: 24px;
      }
      &__body {
        grid-template-columns: none;
        grid-template-rows: repeat(2, auto);
        grid-row-gap: 14px;
        #answers span {
          width: 100%;
        }
        &__form {
          &__options {
            justify-content: center;
            gap: 8px;
          }
        }
      }
    }
  }

  .my__emprops{
    .filters {
        column-gap: 16px;

        .sort{
            column-gap: 16px;
        }
    }
  }
}
@include respond-to("small and down") {
  .my__emprops {
    &.container {
        margin: 0px auto;
    }
    .tokens {
        display: grid;
        grid-template-columns: unset;
        grid-row-gap: 16px;
    }
    .header{        
        &__info {
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
        }
        &__title {
            gap: 8px;
            flex-direction: column;
            align-items: flex-start;
        }
    }
    .filters {
        flex-direction: column;

        h3{
            display: none;
        }

        .sort{
            width: 100%;

            .sort_by{
                display: none;
            }
            input {
                width: 100%;
            }
        }
    }
  }
  .modal {
    &__content {
      width: calc(100vw - 20px);
      height: calc(100vh - 54px);
      &__body {
        #answers span {
          width: 100%;
        }
        &__form {
          &__options {
            flex-direction: column;
            gap: 8px;
          }
        }
      }
      &__footer {
        .btn {
          width: 100%;
        }
      }
    }
  }
}


#iframe {
    position: fixed;
    width: 1200px;
    height: 900px;
    margin: 0px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin-top: 16px;
    opacity: 0;
    z-index: 0;
    &.showIframe {
        opacity: 0;
    }
}


.center {
    position: relative;
    .spinner {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}
.label {
    color: var(--color-neutral-05) !important;
}
.crimson {
  background-color: #501111;
}
.olive_drab {
  background-color: #40502F;
}
.blue_steel {
  background-color: #333F50;
}
.rust {
  background-color: #50442C;
}
.golden {
  background-color: #504B1A;
}
.camo_grey {
  background-color: #4F5043;
}
.blue_beret {
  background-color: #1A3050;
}
.mud {
  background-color: #3C280A;
}
.shield {
  background-color: #414450;
}
.rally_point {
  background-color: #501A00;
}
.acrid {
  background-color: #2A2E34;
}
.cold {
  background-color: #3D4950;
}
.guardian {
  background-color: #502B2B;
}
.heat {
  background-color: #503703;
}
.woodland {
  background-color: #48502F;
}
.valor {
  background-color: #501616;
}
</style>
